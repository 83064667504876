<template>
  <div class="content p-0">
    <div class="login-wrapper">
      <div class="row m-0">
        <div
          class="col-lg-4 h-100-vh side-banner"
          style="
            background-image: url('/static/images/bg-login.jpg');
            background-position: center center;
            background-size: cover;
          "
        >
          <div class="content px-5 text-center d-flex justify-content-center h-100">
            <div class="align-self-center">
              <img
                src="/static/images/FIAF LOGO.svg"
                style="max-height: 60px"
                class="mb-4"
                alt="logo"
              />
              <h4 class="text-white">
                Trabajamos por el progreso de las Franquicias en Iberoamérica
              </h4>
              <router-link to="/" class="btn btn-secondary">Inicio</router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-8 site-content d-flex align-items-center">
          <div class="content" style="width: inherit">
            <div class="row">
              <div class="col-lg-7 mx-auto">
                <div class="text-center mb-4">
                  <h2>¿Olvido su contraseña?</h2>
                  <p>Ingrese su correo electronico registrado en la plataforma</p>
                </div>
                <div class="card bg-secondary shadow border-0">
                  <div class="card-body px-lg-5 py-lg-5">
                    <v-form action class="form" ref="form" v-model="valid">
                      <v-text-field
                        prepend-inner-icon="mdi-email-lock"
                        :rules="[rules.required, rules.email]"
                        @input="checkEmail()"
                        v-model="email"
                        id="email"
                        required
                        type="email"
                        placeholder="Ingresa tu correo electrónico"
                      ></v-text-field>
                      <div class="text-center">
                        <button
                          @click.prevent="signup()"
                          class="btn btn-primary"
                          style="
                            color: #fff;
                            background-color: #009ada;
                            border-color: #009ada;
                          "
                        >
                          Procesar<v-icon dark right> mdi-checkbox-marked-circle </v-icon>
                        </button>
                      </div>
                    </v-form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Recovery',
    data: () => ({
      email: '',
      error: false,
      valid: true,
      rules: {
        required: (value) => !!value || 'Requerido',
        min: (v) => v.length >= 6 || 'Escriba por lo menos 6 caracteres',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'El formato de correo electrónico inválido'
        },
      },
    }),
    methods: {
      checkEmail() {},
      signup() {
        axios
          .post('password/create', {
            email: this.email,
          })
          .then((response) => {
            this.$router.push('/activate-password')
          })
          .catch((error) => {
            this.error = true
          })
      },
    },
  }
</script>
